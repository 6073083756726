import React from "react";
import { Button } from "../button/button";
import Singleinput from "../inputs/singleinput";
import "./informed.scss"

export const Informed = () => {

    return (
        <section className="informed">
            <div className="informed__text">
                <p>
                    Want to be informed
                    when we launch.
                </p>
                <p>
                    Enter your email address to stay up to date with 
                    our product.
                </p>
            </div>
            <div className="informed__input">
                <Singleinput 
                    name="mail"
                    type="text"
                    placeholder="Email Address"
                    disabled={false}
                />

                <Button name="Notify Me"/>
            </div>

        </section>
    )
}