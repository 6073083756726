import "./error.scss"
import Errorsvg from "../../assets/images/error.svg"
import { Button } from "../../components/button/button";
import { useHistory } from "react-router";
export const Error = ()=> {

    const history = useHistory();
    var move = () => {
        history.push("/");
    }
    return (
        <div className="error-404-container">
            <div className="error-404-inner">
                <div className="error-404-inner-main">
                    <img src={Errorsvg} alt="Error-404-svg"/>
                    <p>Oops</p>
                    <p>
                    We do not have the page you are currently looking for, maybe it was removed or renamed.
                    </p>
                    <Button
                        name ="Back To Homepage"
                        onClick ={move}
                    />
                </div>

            </div>

        </div>
    )
}