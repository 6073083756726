import './home.scss';
import { Banner } from '../../components/banner/banner';
import { Platform } from '../../components/platform/platform';
import { Offerings } from '../../components/offerings/offerings';
import { Know } from '../../components/know/know';
import { Informed } from '../../components/informed/informed';
function Home() {
  return (
    <div className="home">
      <Banner/>
      <Platform/>
      <Offerings/>
      <Know/>
      <Informed/>
    </div>
  );
}

export default Home;
