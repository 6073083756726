import React from "react"
import tablet from "../../assets/images/tablet.png"
import bannericons from "../../assets/images/banner-icons.svg"
import "./banner.scss"

export const Banner = () => {

    return (
        <section className="banner">
            <div className="banner__inner">
                <div className="banner__inner__text">
                    <p>COMING SOON :</p>
                    <p>
                        A Proactive Solution 
                        to<br/> Assess Regulatory Risk 
                    </p>
                </div>
                <img className="banner__inner__tab" src={tablet} alt="tab"/>
                <img className="banner__inner__icons" src={bannericons} alt="icons"/>

            </div>
            
            {/* <div class="custom-shape-divider-bottom-1648766796">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
            </div> */}
                
        </section>
    )
}