import './App.scss';
import { Navbar } from './components/navbar/navbar';
import { Footer } from './components/footer/footer';
import Home from './containers/Home/home';
import { Error } from './containers/Error/error';
import { Switch, Route } from 'react-router';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route component={Error} />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
