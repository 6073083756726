import React from "react";
import "./offerings.scss"
import limage from "../../assets/images/l-image.png"
import rimage from "../../assets/images/r-image.png"


export const Offerings = () => {
    var data = [
        {
            title:'Designed for simplicity',
            subtitle:'Arrive at your results in seconds by clicking through a few screens on our state of the art platform.'
        },
        {
            title:'Driven by data',
            subtitle:'Your basic input is enriched with data obtained from our numerous providers to deliver comprehensive results.'
        },
        {
            title:'Monitor the relevant risk',
            subtitle:'Questions are carefully catalogued under the relevant risk factors as prescribed by the regulators'
        }
    ]

    return (
        <section className="offering">

            <img className="offering__limage" src={limage} alt="l pic" />
            <img className="offering__rimage" src={rimage} alt="l pic" />
            <div className="offering__content">
                {
                    (data ?? []).map ((item, index)=> (
                        <Singleoffering 
                            key={index}
                            data ={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}

const Singleoffering= (props)=> {
    return (
        <div className="singleoffering" key={props.key}>
            <p>{props.data.title}</p>
            <p>{props.data.subtitle}</p>
        </div>
    )
}