import React from "react";
import logo from "../../assets/images/reglogo-w.svg"
import "./footer.scss"

export const Footer = () => {

    return (
        <section className="footer">
            <div className="footer__inner">
                <img src={logo} alt="w logo"/>

                <div className="footer__inner__links">
                    {/* <p>Terms & Conditions</p> */}
                    <p>Privacy Policy</p>
                    {/* <p>Cookies Notice</p> */}
                </div>
                <div className="footer__inner__socials">
                    <i class="fab fa-linkedin" onClick={() => window.open('https://www.linkedin.com/products/spectra-risk-solutions-regcomply/')}></i>
                    <i class="fab fa-twitter-square"  onClick={() => window.open('https://twitter.com/RegComply')}></i>
                </div>

                <div className="footer__inner__bottom">
                    <p>
                        RegComply is wholly owned by Spectra Risk Solutions LTD, 
                        a UK registered company
                    </p>
                    <p>
                        © 2021 RegComply All rights reserved
                    </p>
                </div>
            </div>
            
        </section>  
    )
}