import React from "react";
import { Button } from "../button/button";
import "./platform.scss"

export const Platform = () => {

    return (
        <section className="platform">
            <div className="platform__text">
                <p>
                    A powerful & accurate
                    risk rating solution platform
                </p>
                <p>
                    Well-crafted platform with  input from regulatory professionals
                    with expertise in drafting  AML policies and customer risk 
                    methodologies.
                </p>
            </div>
            
            <Button name="Contact us"/>
        </section>
    )
}