import React from "react";
import risk from "../../assets/images/risk.svg"
import mltf from "../../assets/images/mltf.svg"
import bespoke from "../../assets/images/bespoke.svg"
import "./know.scss"

export const Know = ()=> {
    var data = [
        {   
            image:risk,
            title:'Identity risk',
            subtitle:'Electronic identity verification including PEP & Sanctions check and Geography assessment'
        },
        {
            image:mltf,
            title:'ML/TF risk',
            subtitle:'Up to 7 risk factors assessed to deliver an accurate ML/TF risk rating in seconds.'
        },
        {
            image:bespoke,
            title:'Bespoke risk',
            subtitle:'Create your unique assessments based on your company’s bespoke requirements.'
        }
    ]

    return (
        <section className="know">
            <div className="know__left">
                <p>Get to know the platform</p>
                <p>
                Our tool can be used to satisfy use cases such as identity verification & Money laundering/ terrorist financing (ML/TF risk). 
                Industries such as Accounting, Real estate, insurance & financial services must comply with regulations underpinning these 
                use cases at the inception of a business relationship & periodically.
                </p>
                <p>
                    {/* Learn More 
                    <i className="fas fa-chevron-right"/> */}
                </p>
            </div>
            <div className="know__right">
                {
                    (data ?? []).map((item,index)=> (
                        <Singleknow
                            key={index}
                            data={item}
                        />
                    ))
                }
            </div>
        </section>
    )
    
}

const Singleknow = (props)=> {

    return (
        <div className="singleknow" key={props.key}>
            <img src={props.data.image} alt="know icon"/>
            <div className="singleknow__content">
                <p>{props.data.title}</p>
                <p>{props.data.subtitle}</p>
            </div>
        </div>
    )
}