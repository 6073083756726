import React from "react"
import Logo from "../../assets/images/reglogo-b.svg"
import { Button } from "../button/button"
import "./navbar.scss"

export const Navbar = ()=> {

    return (
        <header>
            <div className="header">
                <img src={Logo} alt="Logo"/>
                <Button
                    name="Contact us"
                />
            </div>
        </header>
    )
}